.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;  /* Reduced padding */
  height: 50px; /* Set a fixed height */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  font-family: 'Patrick Hand', cursive;
  position: fixed; /* Fix the navbar at the top */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3; /* Ensure the navbar is above other content */
  box-sizing: border-box; /* Include padding in the element's total width and height */
  background-color: var(--background-color); /* Use the solid background color by default */
}

@media (min-width: 769px) {
  .navbar {
    backdrop-filter: blur(10px);
    background-color: var(--overlay-color);
  }
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.logo {
  height: 28px; /* Slightly smaller */
  width: 28px;
  margin-right: 6px;
}

.brand-name {
  font-size: 20px; /* Slightly smaller text */
}

.burger-menu {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 16px; /* Reduced gap */
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

.nav-links li {
  display: inline;
}

.nav-links a {
  text-decoration: none;
  font-size: 16px; /* Slightly smaller text */
  color: black;
  padding: 6px 10px; /* Reduce padding for a tighter fit */
}

.nav-links a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .burger-menu {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    text-align: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 2; /* Ensure the menu appears above other content */
    padding: 0 16px; /* Add padding to ensure links are within the viewport */
    box-sizing: border-box; /* Include padding in the element's total width and height */
    background-color: var(--background-color); /* Use the solid background color */
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 1rem 0;
  }
}
