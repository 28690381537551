.image-gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%; /* Make it full width */
  max-width: 1200px; /* Use the same max-width as App.css */
  margin: 0 auto; /* Center the container */
  box-sizing: border-box; /* Include padding in width calculation */
}

.image-container {
  margin: 10px;
  transition: transform 0.3s ease;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-container:hover {
  transform: scale(1.05);
}

.caption {
  margin-top: 10px;
  font-family: 'Patrick Hand', cursive;
  font-size: 1.5rem;
  text-align: center;
}

.image-link {
  text-decoration: none;
  color: inherit;
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.text-wrapper {
  width: 100%;
  max-width: 1200px; /* Use the same max-width as App.css */
  margin: 0 auto;
  padding: 0 20px;
  font-size: 1.1rem;
  line-height: 1.6;
  box-sizing: border-box; /* Include padding in width calculation */
}
