:root {
  --background-color: #F3F3F3;
  --overlay-color: rgba(243, 243, 243, 0.2);
}

/* Global font settings */
h1, h2, h3, h4, h5, h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-family: 'Patrick Hand';
  margin: 1.5rem 0;  /* Consistent margin for all headings */
}

h1, h1 a {
  font-size: 3.5rem;
}

h2, h2 a {
  font-size: 2.8rem;
}

h3, h3 a {
  font-size: 2.2rem;
}

p, span, div, a {
  font-family: 'Space Mono', monospace;
  font-size: 1rem;
  line-height: 1.6;
  margin: 1.5rem 0;
}

.App {
  overflow-x: hidden;
}

body {
  background-color: var(--background-color);
}

/* Common container styles */
.common-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

.inner-container {
  max-width: 1200px;
  width: 100%;
  padding: 0 20px; /* Add padding to improve readability */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

/* Content container styles */
.content-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.profile-image-container {
  width: 300px;
  max-width: 300px;
}

.text-container {
  flex: 1;
  text-align: left;
  padding: 0 20px; /* Add padding to improve readability */
}

/* Responsive styles */
@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    text-align: center;
  }

  .profile-image-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .text-container {
    text-align: center;
    padding: 0 20px; /* Ensure padding is applied on smaller screens */
  }

  p {
    text-align: justify; /* Spread out text evenly */
    padding: 0 10px; /* Add padding for better readability */
  }

  h1 { font-size: 2.5rem; }
  h2 { font-size: 2rem; }
  h3 { font-size: 1.75rem; }
  p { font-size: 0.9rem; }
}

@media (min-width: 769px) and (max-width: 1024px) {
  h1 { font-size: 3rem; }
  h2 { font-size: 2.4rem; }
  h3 { font-size: 2rem; }
  p { font-size: 1rem; }
}
